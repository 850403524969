import { useEffect } from "react";
import { useRouter } from "next/navigation";

import getEnv from "@utils/getEnv";

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    if (!["local", "dev", "test"].includes(getEnv().ENV || "")) {
      // Redirect to 404
      router.replace("/404");
    } else {
      // redirect to example
      router.replace("/example/landing");
    }
  }, [router]);

  return null;
}
